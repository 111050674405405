<template>
    <aside class="w-64 bg-gray-800 text-white flex flex-col">
    <nav class="main-menu flex-1">

        <ul class="space-y-2">
  <li>
    <router-link 
      to="/faq/chatbot-ia/duotalk-ia" 
      class="block py-2 hover:activeChild rounded" 
      active-class="activeChild"
    >
      Duotalk IA
    </router-link>
  </li>
  <li>
    <router-link 
      to="/faq/chatbot-ia/base-conhecimento" 
      class="block py-2 hover:activeChild rounded" 
      active-class="activeChild"
    >
      Base de Conhecimento
    </router-link>
  </li>
  <li>
    <router-link 
      to="/faq/chatbot-ia/prompt-atendimento" 
      class="block py-2 hover:activeChild rounded" 
      active-class="activeChild"
    >
      PROMPT de Atendimento
    </router-link>
  </li>
  <li>
    <router-link 
      to="/faq/chatbot-ia/configuracoes-avancadas" 
      class="block py-2 hover:activeChild rounded" 
      active-class="activeChild"
    >
      Configurações Avançadas
    </router-link>
  </li>
  <li>
    <router-link 
      to="/faq/chatbot-ia/limitacoes-chatgpt" 
      class="block py-2 hover:activeChild rounded" 
      active-class="activeChild"
    >
      Limitações do ChatGPT
    </router-link>
  </li>
  <li>
    <router-link 
      to="/faq/chatbot-ia/exemplos-prompts" 
      class="block py-2 hover:activeChild rounded" 
      active-class="activeChild"
    >
      Exemplos de PROMPTs
    </router-link>
  </li>
</ul>



    </nav>
    </aside>


  </template>
  
  <script>
  export default {
    name: 'MenuFaqIA'
  };
  </script>
  
  <style scoped>

  
  .main-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .main-menu ul li {
    display: inline;
    margin-right: 20px;
  }
  
  .main-menu ul li a {
    text-decoration: none;
    color: #444;
  }
  
  .main-menu ul li a:hover {
    color: rgba(59, 130, 246, 0.5);
  }

  .main-menu ul li a.activeChild {
    color: #7367f0;
  }
  </style>