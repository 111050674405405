<template>
  <div class="p-0">
    <div class="bg-white p-8 rounded-lg shadow-xl py-8 mt-12">
      <h3 class="text-lg font-semibold">Documentação - Inteligência Artificial</h3>
      <div class="pb-12 mt-4">

        <!-- Layout Responsivo -->
        <div class="flex min-h-screen flex-row py-8">
          <!-- Sidebar -->
          <Menu />
          <!-- Main Content -->
          <main class="flex-1 px-6 bg-gray-100">
            
            <h1 class="text-2xl font-bold mb-4">Funcionamento da Base de Conhecimento</h1>
  <p class="mb-4">
    A base de conhecimento é o conjunto de informações que o chatbot utiliza para responder às perguntas dos usuários. Ela pode ser composta por documentos de diversos formatos, como PDF, TXT, DOC e DOCX, com um limite de 100 arquivos de 50mb. No entanto, para que o chatbot acesse essas informações corretamente, o conteúdo deve estar bem estruturado e, preferencialmente, em formatos legíveis pela IA.
  </p>

  <h2 class="text-xl font-semibold mb-3">Formatos de Documentos Aceitos</h2>
  
  <ul class="list-disc list-inside mb-4">
    <li class="mb-2">
      <strong>Textos simples (TXT, DOCX):</strong> São os mais recomendados, pois facilitam o processamento das informações.
    </li>
    <li class="mb-2">
      <strong>PDFs:</strong> São aceitos, mas podem apresentar limitações, especialmente se o documento contiver muitas imagens, tabelas complexas ou gráficos. O ideal é que o conteúdo seja predominantemente texto e em layout linear.
    </li>
    <li class="mb-2">
      <strong>Tabela Pipe:</strong> Estruturas com formato de tabelas baseadas em caracteres pipes (|) podem ser interpretadas de forma mais precisa.
      <div class="py-6 mb-8"> 
  <div class="bg-white overflow-auto rounded-lg shadow-md ">
    <pre class="whitespace-pre-wrap font-mono p-4">
| Versão               | Motor      | Cavalos | Preço        |
|----------------------|------------|---------|--------------|
| VW Polo 1.0 TSI      | 1.0 Turbo  | 128 cv  | R$ 99.990,00 |
| VW Golf 1.4 TSI      | 1.4 Turbo  | 150 cv  | R$ 139.990,00|
| VW Tiguan Allspace   | 2.0 Turbo  | 220 cv  | R$ 215.000,00|
| VW Jetta GLI 2.0     | 2.0 Turbo  | 230 cv  | R$ 229.990,00|
| VW Amarok V6         | 3.0 Turbo  | 258 cv  | R$ 330.000,00|
    </pre>
  </div>
  </div>

    </li>
    <li class="mb-2">
      <strong>JSON:</strong> Para conteúdo dinâmico e estruturado, o formato JSON pode ser útil, desde que bem definido.
      <div class="py-4 mb-8">
  <div class="bg-white overflow-auto rounded-lg shadow-md ">
    <pre class="whitespace-pre-wrap font-mono p-4">
{
  "veiculos": [
    {
      "versao": "VW Polo 1.0 TSI",
      "motor": "1.0 Turbo",
      "cavalos": "128 cv",
      "preco": "R$ 99.990,00"
    },
    {
      "versao": "VW Golf 1.4 TSI",
      "motor": "1.4 Turbo",
      "cavalos": "150 cv",
      "preco": "R$ 139.990,00"
    },
    {
      "versao": "VW Tiguan Allspace",
      "motor": "2.0 Turbo",
      "cavalos": "220 cv",
      "preco": "R$ 215.000,00"
    },
    {
      "versao": "VW Jetta GLI 2.0",
      "motor": "2.0 Turbo",
      "cavalos": "230 cv",
      "preco": "R$ 229.990,00"
    },
    {
      "versao": "VW Amarok V6",
      "motor": "3.0 Turbo",
      "cavalos": "258 cv",
      "preco": "R$ 330.000,00"
    }
  ]
}
    </pre>
  </div>
</div>

    </li>
  </ul>

  <h2 class="text-xl font-semibold mb-3">Limitações com Certos Tipos de Documentos</h2>

  <ul class="list-disc list-inside mb-4">
    <li class="mb-2">
      <strong>PDFs com muitas imagens ou tabelas:</strong> O ChatGPT pode não conseguir processar corretamente imagens ou tabelas densas em arquivos PDF, o que pode resultar em respostas incompletas ou incorretas.
    </li>
    <li class="mb-2">
      <strong>Links para sites externos:</strong> O chatbot não navega em páginas da web. Simplesmente inserir um link de um site na base de conhecimento não permitirá que a IA busque informações de forma autônoma.
    </li>
    <li class="mb-2">
      <strong>Tabelas de dados:</strong> Tabelas com muitas colunas ou dados muito próximos podem confundir a IA, resultando em erros na interpretação de termos ou dados exatos.
    </li>
  </ul>

  <p class="mb-4">
    Em casos complexos, é recomendável fornecer um direcionamento claro no prompt para que a IA processe as informações de forma mais precisa.
  </p>

          </main>
        </div>

      </div>
    </div>
  </div>
</template>
    
    
      
      <script>
      import Menu from './MenuFaqIA.vue'; // Importando o menu
      export default {
        name: 'ChatbotIA-BaseConhecimento',
        components: {
            Menu
        },
        data() {
        return {
          // Lista de perguntas e respostas, o primeiro item já vem com `active: true`
          faqs: [
            { question: "O que é Duotalk?", answer: "Duotalk é uma plataforma que oferece soluções de atendimento automatizado.", active: true },
            { question: "Como configurar a inteligência artificial?", answer: "Você pode configurar a inteligência artificial seguindo as instruções no nosso guia.", active: false },
            { question: "Quais são os planos disponíveis?", answer: "Os planos variam de acordo com o número de usuários e as funcionalidades necessárias.", active: false },
          ]
        };
      },
      methods: {
        // Alterna a visibilidade da resposta
        toggleFaq(index) {
          this.faqs[index].active = !this.faqs[index].active;
        }
      }
    };
    
    </script>
    
    
    <style scoped>

    
    .faq-item h3 {
      cursor: pointer;
      padding: 10px;
      background-color: #f2f2f2;
      border-radius: 5px;
      margin: 5px 0;
      transition: background-color 0.3s ease;
    }
    
    .faq-item h3:hover {
      background-color: #e0e0e0;
    }
    
    .faq-item h3.active {
      background-color: #d0d0d0;
    }
    
    .faq-item p {
      margin: 10px 0;
      padding: 10px;
      background-color: #fafafa;
      border-left: 5px solid #cccccc;
    }
    </style>
   